export const portfolioData = [
  {
    title: "San Francisco Pride",
    href: "https://www.sfpride.org",
    category: { name: "Event" },
    description:
      "Responsible for logistics and fulfillment for an event that generates $500,000 in revenue. The program involves local non-profits and provides them a revenue earning opportunity.",
    date: "2014 - 2017",
    datetime: "2014-07-16",
    imageUrl: "/pride.png",
    imageClassName: "object-cover",
    author: {
      name: "Beverages Manager",
    },
    orderKey: 2014,
  },
  {
    title: "The Awesome 80's Prom",
    href: "https://en.wikipedia.org/wiki/The_Awesome_80s_Prom",
    category: { name: "Theater" },
    description:
      "Setup/Breakdown of scenic elements and props. Seat guests at reserved tables. Supervise stage activities and act as security for actors while extending the interactive environment.",
    date: "2006 - 2009",
    datetime: "2006-07-16",
    imageUrl: "/Awesome_80s_Prom.jpg",
    imageClassName: "object-cover",
    author: {
      name: "Props Master & Assistant Stage Manager",
    },
    orderKey: 2006,
  },
  {
    title: "My First Time",
    href: "https://en.wikipedia.org/wiki/My_First_Time_(play)",
    category: { name: "Theater" },
    description:
      "Developed projection materials, designed lights, sound, projections, and coordinated production's needs for an open-ended run Off-Broadway.",
    date: "2007 - 2009",
    datetime: "2007-07-16",
    imageUrl: "/Myfirsttimeplay.jpg",
    imageClassName: "object-cover object-[50%_75%]",
    author: {
      name: "Production Designer",
    },
    orderKey: 2007,
  },
  {
    title: "Times Square New Year's Eve",
    href: "https://en.wikipedia.org/wiki/Times_Square_Ball",
    category: { name: "Event" },
    description:
      "Assisted the Production Manager in day-to-day operations, petty cash accounting, coordination of VIP Party, and runner for performing talent.",
    date: "2005",
    datetime: "2004-12-31",
    imageUrl: "/luo-lei-HbNCZ0k-txA-unsplash.jpg",
    imageClassName: "object-cover",
    author: {
      name: "Production Assistant",
    },
    orderKey: 2005,
  },
  {
    title: "Lincoln Center Institute Summer Session",
    href: "http://www.aboutlincolncenter.org/education-community/lincoln-center-education",
    category: { name: "Event" },
    description:
      "Coordinated supplies and Teaching Artist needs across 34 workshops that train educators. Developed a database to facilitate workshop needs, purchased supplies and analyzed participant surveys.",
    date: "2005",
    datetime: "2005-06-01",
    imageUrl: "/enric-domas-Q4OKvB2q_g8-unsplash.jpg",
    imageClassName: "object-cover",
    author: {
      name: "Workshop Support Intern",
    },
    orderKey: 2005,
  },
  {
    title: "Artsplosure",
    href: "https://www.artsplosure.org",
    description:
      "Transported materials and volunteers to venues. Facilitated artist/volunteer hospitality. Assisted children's activities and with break down.",
    category: { name: "Event" },
    date: "2001 - 2005",
    datetime: "2001-04-01",
    imageUrl:
      "https://images.squarespace-cdn.com/content/v1/5ef4abc25622935ab7fb13be/1594676633030-FE5MZVJ69X6O37UOUTVJ/Artsplosure_Logo_W.png?format=1500w",
    imageClassName: "object-cover",
    author: {
      name: "Volunteer Coordinator/Production Assistant",
    },
    orderKey: 2001,
  },
  {
    title: "Tri-State Scupltor’s Conference",
    href: "https://tristatesculptors.org",
    category: { name: "Event" },
    description:
      "Responsible for overall flow of event. Created sponsor and program signage, worked with campus police to permit and ensure safety for an iron pour. Developed registration database and participant identification.",
    date: "2004",
    datetime: "2004-02-01",
    imageUrl: "/tod-s-lgnEI45raTI-unsplash.jpg",
    imageClassName: "object-cover",
    author: {
      name: "Event Coordinator",
    },
    orderKey: 2004,
  },
  {
    title: "First Night Raleigh",
    href: "https://firstnightraleigh.com",
    category: { name: "Event" },
    description:
      "Developed policies, training collateral and train Site/Event Managers. Facilitated data collection and developed database for post event analysis.",
    date: "2000 - 2005",
    datetime: "2000-10-01",
    imageUrl: "/higgins-spooner-_RGHUZkpcNY-unsplash.jpg",
    imageClassName: "object-cover",
    author: {
      name: "Venue Manager",
    },
    orderKey: 2000,
  },
  {
    title: "UNCSA Winter Dance Concert",
    href: "https://www.uncsa.edu",
    category: { name: "Theater" },
    description:
      "Coordinated staff and equipment across simultaneous rehearsals. Organized scheduling and transportation around both high school and college classes. Called cues for Balanchine work, Symphonie Concertante, staged by Melissa Hayden, and world premiere Alex Ketley work, Field Effect.",
    date: "2005",
    datetime: "2005-10-30",
    imageUrl: "/bruno-horwath-rSUeHOMrhZs-unsplash.jpg",
    imageClassName: "object-cover object-bottom",
    author: {
      name: "Stage Manager",
    },
    orderKey: 2005,
  },

  {
    title: "Chicago",
    href: "https://www.ltofws.org",
    category: { name: "Theater" },
    description:
      "Responsible for props tracking and firearms. Assisted in rehearsals and on-deck during the show. Directed and choreographed by Jerry Hatmaker at The Little Theater of Winston Salem.",
    date: "2003",
    datetime: "2003-10-01",
    imageUrl: "/alexander-abero-OhKNR0uSKsA-unsplash.jpg",
    imageClassName: "object-cover object-[50%_20%]",
    author: {
      name: "Assistant Stage Manager",
    },
    orderKey: 2003,
  },

  {
    title: "The Guys",
    href: "https://en.wikipedia.org/wiki/The_Guys",
    category: { name: "Theater" },
    description:
      "Rehearsed, coordinated props, and called performances for a staged reading with Terrance Mann and Charolotte d'Amboise. Production to benefit North Carolina Fallen Firefighters Association.",
    date: "2005",
    datetime: "2005-06-01",
    imageUrl: "/courtney-wentz-IY36w2ESOJc-unsplash.jpg",
    imageClassName: "object-cover",
    author: {
      name: "Stage Manager",
    },
    orderKey: 2005,
  },
];

// eslint-disable-next-line no-unused-vars
const example = {
  title: "",
  href: "",
  category: { name: "Event" },
  description: "",
  date: "2005",
  datetime: "2005-06-01",
  imageUrl: "",
  imageClassName: "object-cover",
  author: {
    name: "",
  },
  orderKey: 2005,
};
