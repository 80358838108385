import { portfolioData } from "../api/portfolioData";
import { Footer } from "./Footer";
import { Header } from "./Header";

const dateSorter = (a, b) => {
  const keyA = a.orderKey;
  const keyB = b.orderKey;
  // Compare the 2 dates
  if (keyA < keyB) return -1;
  if (keyA > keyB) return 1;
  return 0;
};

export const Portfolio = () => {
  return (
    <div>
      <Header showName={true} />

      <div className="relative bg-gray-900 pt-16 pb-20 px-4 sm:px-6 lg:pt-12 lg:pb-12 lg:px-8">
        <div className="absolute inset-0">
          <div className="bg-gray-900 h-1/3 sm:h-2/3" />
        </div>
        <div className="relative max-w-7xl mx-auto">
          <div className="text-center">
            <h2 className="text-3xl tracking-tight font-extrabold text-white sm:text-4xl">
              Portfolio
            </h2>
            <p className="mt-3 max-w-2xl mx-auto text-xl text-gray-500 sm:mt-4">
              Selected theater &amp; event experience.
            </p>
          </div>
          <div className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
            {portfolioData
              .sort(dateSorter)
              .reverse()
              .map((post) => (
                <div
                  key={post.title}
                  className="flex flex-col rounded-lg shadow-lg overflow-hidden"
                >
                  <div className="flex-shrink-0">
                    <img
                      className={`h-48 w-full ${post.imageClassName}`}
                      src={post.imageUrl}
                      alt=""
                    />
                  </div>
                  <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                    <div className="flex-1">
                      <p className="text-sm font-medium text-indigo-600">
                        {post.category.name}
                      </p>
                      <a
                        href={post.href}
                        className="block mt-2"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <p className="text-xl font-semibold text-gray-900">
                          {post.title}
                        </p>
                        <p className="mt-3 text-base text-gray-500">
                          {post.description}
                        </p>
                      </a>
                    </div>
                    <div className="mt-6 flex items-center">
                      <div className="">
                        <p className="text-sm font-medium text-gray-900">
                          {post.author.name}
                        </p>
                        <div className="flex space-x-1 text-sm text-gray-500">
                          <time dateTime={post.datetime}>{post.date}</time>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
